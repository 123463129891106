<template>
  <div>
    <base-template
        v-if="isReady"
        title="Titre"
        :items="incomingInvoices2"
        :items-context="incomingInvoicesContext"
        :available-filters="availableFilters"
        :payload.sync="payload"
        :module="module"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import i18n from '../../libs/i18n'
import store from '../../store'

import BaseTemplate from '../../components/base3/Base.vue'
import useAPI from '../../utils/useAPI'

export default {
  components: { BaseTemplate },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('supplierInvoices')
    const availableFilters = ref([
      {
        name: '_supplierInvoice_reconciliationStatus',
        field: '_supplierInvoice_reconciliationStatus',
        i18n: capitalize(i18n.t('reconciliationStatus')),
        subFields: [],
        isSpecific: true,
        type: 'select',
        options: [
          {
            i18n: capitalize(i18n.t('lettered')),
            value: 'lettered'
          },
          {
            i18n: capitalize(i18n.t('unlettered')),
            value: 'unlettered'
          },
          {
            i18n: capitalize(i18n.t('partiallyLettered')),
            value: 'partially lettered'
          }
        ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
      },
      {
        name: '_supplierInvoice_bankReconciliationStatus',
        field: '_supplierInvoice_bankReconciliationStatus',
        i18n: capitalize(i18n.t('bankReconciliationStatus')),
        subFields: [],
        isSpecific: true,
        type: 'select',
        options: [
          {
            i18n: capitalize(i18n.t('paid')),
            value: 'paid'
          },
          {
            i18n: capitalize(i18n.t('unpaid')),
            value: 'unpaid'
          },
          {
            i18n: capitalize(i18n.t('partiallyPaid')),
            value: 'partially paid'
          }
        ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
      },
      {
        field: 'createdAt',
        i18n: capitalize(i18n.t('createdAt')),
        subFields: [],
        type: 'date',
      },
      {
        field: 'createdBy',
        i18n: capitalize(i18n.t('createdBy')),
        subFields: [],
        type: 'employee',
      },
      {
        field: 'discount',
        i18n: capitalize(i18n.t('discount')),
        subFields: [],
        type: 'text',
      },
      {
        field: 'preTaxAmount',
        i18n: capitalize(i18n.t('preTaxAmount')),
        subFields: [],
        type: 'number',
      },
      {
        field: 'totalAmount',
        i18n: capitalize(i18n.t('totalAmount')),
        subFields: [],
        type: 'number',
      },
      {
        field: 'vat',
        i18n: capitalize(i18n.t('vat')),
        subFields: [],
        type: 'number',
      },
      {
        field: 'billingDate',
        i18n: capitalize(i18n.t('billingDate')),
        subFields: [],
        type: 'date',
      },
      {
        field: 'billNumber',
        i18n: capitalize(i18n.t('billNumber')),
        subFields: [],
        type: 'text',
      },
      {
        field: 'paymentDate',
        i18n: capitalize(i18n.t('paymentDate')),
        subFields: [],
        type: 'date',
      },
      {
        field: 'type',
        i18n: capitalize(i18n.t('type')),
        subFields: [],
        type: 'text',
      },
      {
        field: 'title',
        i18n: capitalize(i18n.t('title')),
        subFields: [],
        type: 'text',
      },
      {
        field: 'supplierCompany',
        i18n: capitalize(i18n.t('supplier')),
        subFields: ['name'],
        type: 'text',
      },
    ].sort((a, b) => a.i18n < b.i18n ? -1 : 1))
    const payload = ref({})
    const isReady = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { incomingInvoices2, incomingInvoicesContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    watch(payload, val => {
      isReady.value = true
      fetchIncomingInvoices2(val)
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchIncomingInvoices2 } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch('moduleView/getModuleViews')
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            sorts: currentView.sorts,
          }
        })

    return {
      // Components

      // Data
      module,
      availableFilters,
      payload,
      isReady,

      // Computed
      incomingInvoices2,
      incomingInvoicesContext,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>